import React from "react";
import "../assets/footer.css";

function Footer() {
  const links = [
    { title: "Mais", href: "#home" },
    { title: "Benefícios", href: "#features" },
    { title: "Termos de uso", href: "terms" },
    { title: "Termos de privacidade", href: "privacy" },
    { title: "Assinaturas", href: "#pricing" },
    { title: "Onde baixar", href: "#download" }
  ]
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-30">
            <div className="footer-text__title">SuperTalk</div>
            <div className="footer-text__body">
              Estamos melhorando a comunicação através da inovação e tecnologia.
            </div>
          </div>
          <div className="col-30">
            <div className="footer-text__title">Acesso rápido</div>
            <ul className="footer-list">
              {
                links.map((link) => {
                  const currentUrl = new URL(document.location.href);
                  const externalControlPages = ["/privacy", "/terms"];

                  const referenceLink = externalControlPages.includes(currentUrl.pathname) ? `/${link.href}` : link.href;
                  return (<li>
                    <a href={referenceLink}>{link.title}</a>
                  </li>);
                })
              }
              {/* <li>
                <a href="#home">Mais</a>
              </li>
              <li>
                <a href="#features">Benefícios</a>
              </li> 
              <li>
                <a href="/terms">Termos de uso</a>
              </li>
              <li>
                <a href="/privacy">Termos de privacidade</a>
              </li>
              <li>
                <a href="#pricing">Assinaturas</a>
              </li>
              <li>
                <a href="#download">Onde baixar</a>
              </li> */}
            </ul>
          </div>
          <div className="col-30">
            <div className="footer-text__title">Newsletter</div>
            <div className="footer-text__body">
              Para receber novidades, atualizações e promoções diretamente em seu e-mail, assine o Newsletter.
            </div>
            <div className="footer-input">
              <input type="text" name="email" placeholder="Email " />
              <div className="footer-input__icon">
                <ion-icon name="paper-plane-outline" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="copyright">
          SuperTalkBR@2021.
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
