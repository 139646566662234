import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import App from "./App";
import TermsPolicy from "./components/TermsPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALxboUo6qUEv6MqlepixTIjRyxN5Ds14M",
  authDomain: "supertalk-landing.firebaseapp.com",
  projectId: "supertalk-landing",
  storageBucket: "supertalk-landing.appspot.com",
  messagingSenderId: "605085463925",
  appId: "1:605085463925:web:201754fbc32f0034380f3c",
  measurementId: "G-9YH4380DL0"
};

// Initialize Firebase
try{
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}catch(e){
  console.log("getAnalytics",e);
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>              
        <Route path="/privacy"  element={<PrivacyPolicy />} />
        <Route path="/terms"  element={<TermsPolicy />} />
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);
